import { useRef, useState, useEffect, useContext } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RefreshIcon from "@mui/icons-material/Home";
import { Button, Popover, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAuth0 } from "@auth0/auth0-react";
import Bookmarks from "../../../../layout/Bookmark";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import {QlikContext} from "../../../../contexts/context";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 305,
  },
  button: {
    height: 44,
    padding:8,
    minWidth:'unset',
    [theme.breakpoints.down("xl")]: {
      padding: 5,
    },
  },
  label: {
    flexDirection: "column",
  },
  icon: {
    marginRight: "3px",
  },
  text: {
    fontSize: "13px",
    fontWeight: "bold",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      fontSize: "12px",
    },
  },
  buttonIcon: {
    transform: "rotate(0deg)",
    marginLeft: "auto !important",
  },
  avatar: {
    fontSize: "2.5rem !important",
  },
  bookmarkContainer: {
    minHeight: "10vh",
    maxHeight: "40vh",
    overflow: "auto",
  },
}));

function HeaderUserbox() {
  const { t } = useTranslation();
  const { user, logout } = useAuth0();
  const classes = useStyles();
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileMenu, setProfileMenu] = useState(null);
  const open = Boolean(anchorEl);
  const { qDoc, closeSession } = useContext(QlikContext);

  const handleOpen = (event) => {
    // setOpen(true);
    setProfileMenu(event.currentTarget);
  };
  const handleClose = () => {
    setProfileMenu(null);
  };

  const openBookmark = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleRestart = () => {
    sessionStorage.removeItem("selApp");
    sessionStorage.removeItem("navStates");
    sessionStorage.removeItem("currency");
    closeSession()
    window.location.href = "/";
  };

  useEffect(() => {
    setAnchorEl(null);
  }, []);

  const closeBookmark = () => {
    setAnchorEl(null);
  };

  const helpLink = () => {
    let path = "https://www.skyitgroup.com/skypad-marketplace";
    window.open(path, "_blank");
  };

  return (
    <>
      <Button
        ref={ref}
        onClick={openBookmark}
        /* Use classes property to inject custom styles */
        classes={{ root: classes.button, label: classes.label }}
        className={classes.button}
        variant="raised"
        color="primary"
        disableRipple={true}
      >
        <span className={classes.text}>
          <BookmarkBorderIcon></BookmarkBorderIcon>
          Bookmarks
        </span>
      </Button>

      <Popover
        anchorEl={anchorEl}
        onClose={closeBookmark}
        getContentAnchorEl={null}
        open={open}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Bookmarks closeBookmark={setAnchorEl}></Bookmarks>
      </Popover>

      <Popover
        anchorEl={profileMenu}
        id="account-menu"
        keepMounted
        open={Boolean(profileMenu)}
        onClose={handleClose}
        getContentAnchorEl={null} //puts the dropdown window bottom
        PaperProps={{
          elevation: 0,
          sx: {
            fontWeight: "bold",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleRestart} sx={{ padding: "10px" }}>
          <IconButton
            sx={{ padding: 0, "&:hover": { backgroundColor: "unset" } }}
            variant="raised"
            disableRipple={true}
          >
            <RefreshIcon className={classes.icon} />
            <span className={classes.text}>Return Home</span>
          </IconButton>
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ padding: "10px" }}>
          <IconButton
            ref={ref}
            sx={{ padding: 0, "&:hover": { backgroundColor: "unset" } }}
            variant="raised"
            disableRipple={true}
          >
            <ExitToAppIcon className={classes.icon} />
            <span className={classes.text}>Logout</span>
          </IconButton>
        </MenuItem>
      </Popover>

      <Button
        ref={ref}
        classes={{ root: classes.button, label: classes.label }}
        variant="raised"
        disableRipple={true}
        onClick={handleOpen}
        aria-label="account of current user"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <span className={classes.text}>
          <AccountCircle />
          Hi, {user && user.given_name}
        </span>
      </Button>

      <Button
        ref={ref}
        classes={{ root: classes.button, label: classes.label }}
        variant="raised"
        disableRipple={true}
        onClick={helpLink}
      >
        <span title="Marketplace" className={classes.text}>
          {/* <HelpIcon />
          Help */}
          <LocalGroceryStoreIcon />  
          Marketplace
        </span>
      </Button>
    </>
  );
}

export default HeaderUserbox;
