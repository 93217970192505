import axios from "axios";

let baseUrl = window.location.origin;
if (window.location.hostname === 'localhost') {
   baseUrl = "http://localhost:3033/";
 // baseUrl = "https://hubtest.skypad.biz";
}

export const BASE_URL_AUTH = window.location.origin;
export const BASE_URL = baseUrl;

const axiosInt = axios.create({
  baseURL: BASE_URL + "/",
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error || error.response || error.response.data),
);

export default axiosInt;
