export const text = {
  noDataMessage: "No Data! Error generating file",
  pdfColumnLimit:
    "Please select 20 or less column for PDF export, Export Excel to download all the data.",
  imageRowLimit:
    "Exports with images must be 5,000 rows or less.\nPlease filter down your report then click on the Export button again.",
  fileDownloading: "file is downloading...",
  fileDownloaded: "file has downloaded!",
  exportCanceled: "file export canceled!",
  fileTooLarge: "File is too large to export. Please filter down your data and try exporting again",
  fileNotDownloaded: "file did NOT download!",
  cancelTooltip: "Cancel the export",
  closeTooltip: "Close pop up",
  excelExportTooltip: "Export to Excel",
  pdfExportTooltip: "Export to PDF",
  downloadButton: "Download",
  cancelButton: "Cancel",
  okButton: "OK",
};

export const statusStates = {
  isSuccess: "success",
  isError: "error",
  isAborted: "aborted",
  isProgress: "progress",
  isLargeFile: "large_file",
};
export const failedExportStatus = ["reject", "error", "aborted", "large_file"];

export default { text, statusStates, failedExportStatus };
