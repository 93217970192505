import getDimensions from "./getDimensions";

const timeOut = () => {
  var idleTime = 0;

  setInterval(timerIncrement, 60000);

  document.addEventListener("mousemove", function (e) {
    idleTime = 0;
  });

  document.addEventListener("keypress", function (e) {
    idleTime = 0;
  });

  function timerIncrement() {
    console.log(idleTime);
    idleTime = idleTime + 1;
    if (idleTime > 29) {
      // 20 minutes
      sessionStorage.removeItem("selApp");
      window.location.href = "/";
    }
  }
};

export default timeOut;
