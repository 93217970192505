import { useState, createContext, useContext } from "react";
import { ApiContext } from "./ApiContext";
import resetNavState from "../utils/resetNavState";

export const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const { getConfig, getMetric } = useContext(ApiContext);
  const [configVal, setConfigVal] = useState();

  const getConfigValForApp = async (app) => {
    let response = await getConfig(app);
    let response2 = await getMetric();
    for (const key in response2) {
      response.push({ [key]: response2[key] });
    }
    resetNavState(response);

    response.filter((x) => x.type === "Tab" && x.subtype === "Buttons")[0]
      .value;
  //   response.filter(x => x.type === 'Tab' && x.subtype === 'Buttons')[0].value.push({
  //     "order": 8,
  //     "key": "Reorder",
  //     "label": "\\\\gcp-skypad-fs1\\QVContainer\\00. Container Directory\\ICONS\\DA.png",
  //     "args": "DA"
  // })
    setConfigVal(response);
    // setTrendingVal(response2);
  };

  return (
    <ConfigContext.Provider value={{ configVal, getConfigValForApp }}>
      {children}
    </ConfigContext.Provider>
  );
};
