import { useState, createContext } from "react";
import { periodTypeSort } from "../utils/helpers";
import { WEDSort } from "../services/qlikExpressions/QlikViewExpressions";
import { jsonObjStr } from "../services/qlikHyperCubeDefs/second-period-object";

export const CurrentTimeFrameContext = createContext({});

let qObject = null;
let period1 = null;

export const CurrentTimeFrameProvider = ({ children }) => {
  const [currentTimeFrame, setCurrentTimeFrameContext1] = useState('this is null');


  const update = async () => {
    if (qObject) {
      const _qLayout = await qObject.getLayout();
      let week1 = _qLayout.initialProperties.first.qHyperCube.qDataPages[0].qMatrix[0][0].qText;
      let timePeriod = week1;

      if (period1 != "LW" && period1 != "Day" && period1 != "WTD") {
        let week2 = _qLayout.initialProperties.second.qHyperCube.qDataPages[0].qMatrix[0][1].qText;
        timePeriod = week1 + " - " + week2;
      }
      setCurrentTimeFrameContext1(timePeriod);
    }
  };

  const setCurrentTimeFrameContext = (period, qDoc) => {
    const qDim = period && periodTypeSort[period];
    period1 = period;
    if (period != "LW" && period != "Day" && period != "WTD") {
      jsonObjStr.initialProperties.second = {
        qHyperCubeDef: {
          qDimensions: [
            {
              qDef: {
                qFieldDefs: [qDim["field"]],
                qSortCriterias: [
                  {
                    qSortByNumeric: qDim["numSort"],
                  },
                ],
              },
            },
            {
              qDef: {
                qFieldDefs: ["Week Ending Date"],
                qSortCriterias: [
                  {
                    qSortByExpression: 1,
                    qExpression: WEDSort,
                  },
                ],
              },
            },
            {
              qDef: {
                qFieldDefs: ["weekID"],
                qSortCriterias: [
                  {
                    qSortByNumeric: 1,
                  },
                ],
              },
            },
          ],
          qInitialDataFetch: [
            {
              qWidth: 3,
              qHeight: 3,
            },
          ],
          qInterColumnSortOrder: [0, 2, 1],
        },
      };
    }

    (async () => {
      qObject = await qDoc.createSessionObject(jsonObjStr)

      qObject.on("changed", () => {
        update();
      });
      update();
    }
    )();
  }

  //.then((qObj) => {
  // qObj.getLayout().then((_qLayout) => {
  //   let week1 = _qLayout.initialProperties.first.qHyperCube.qDataPages[0].qMatrix[0][0].qText;
  //   let timePeriod = week1;

  //   if (period != "LW") {
  //     let week2 = _qLayout.initialProperties.second.qHyperCube.qDataPages[0].qMatrix[0][1].qText;
  //     timePeriod = week1 + " - " + week2;
  //   }
  //   setCurrentTimeFrameContext1(timePeriod);
  // })

  return (
    <CurrentTimeFrameContext.Provider value={{ currentTimeFrame, setCurrentTimeFrameContext }}>
      {children}
    </CurrentTimeFrameContext.Provider>
  )
};
