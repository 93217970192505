import { useEffect, useContext } from "react";

import "./Sidebar.css";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import { styled } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { QlikContext } from "../../contexts/QlikContext";
import { embed } from "@nebula.js/stardust";
import { useState } from "react";
import { numericSort, selValLabels } from "../../utils/helpers";
// import { matcher, isMatch } from 'matcher';

const DetailsWrapper = styled(Box)(
  () => `
    .MuiAccordionDetails-root {
        padding: 8px 2px 16px;
    }`,
);
export const getSortExpression = (fieldName) => {
  var qSortCriterias = [
    {
      qSortByState: 1,
      qSortByExpression: 0,
      qExpression: {
        qv: "",
      },

      qSortByAscii: 0,
      qSortByNumeric: 0,
      qSortByLoadOrder: 0,
    },
  ];

  if (fieldName == "Week Ending Date") {
    
    qSortCriterias[0].qSortByNumeric = -1;
    // qSortCriterias[0].qSortByExpression = -1;
    // qSortCriterias[0].qExpression.qv = "num(date(date#(['Week Ending Date'], 'MM/DD/YYYY'), 'MM/DD/YYYY'))";
  } else 
  if (fieldName == "Week #") {
    qSortCriterias[0].qSortByNumeric = 1;
  } else if (fieldName == "Year") {
    qSortCriterias[0].qSortByNumeric = -1;
  } else if (fieldName == "Month") {
    qSortCriterias[0].qSortByExpression = 1;
    qSortCriterias[0].qExpression.qv = "[Month #]";
  } else if (numericSort.includes(fieldName)) {
    qSortCriterias[0].qSortByNumeric = 1;
  } else {
    qSortCriterias[0].qSortByAscii = 1;
  }

  return qSortCriterias;
};

const Filter = ({
  label,
  filterIndex,
  expanded,
  collapseAccordion,
  expandedName,
  prevExpandedName,
  selVal,
}) => {
  const { qDoc } = useContext(QlikContext);
  const [mountedElm, setMounterElm] = useState(null);

  useEffect(() => {
    document.getElementById("accordion") &&
      document
        .getElementById("accordion")
        .addEventListener("selectstart", function (e) {
          e.preventDefault();
        });
  }, []);

  useEffect(async () => {
    const app = qDoc;

    if (prevExpandedName === label) {
      app.abortModal({ qAccept: true });
      const n1 = embed(app);
      n1.field(prevExpandedName).then((x) => {
        x.unmount();
      });
    }
    if (expandedName === label && expanded === true) {
      if (!mountedElm) {
        const fieldName = expandedName;
        let options = {
          title: "",
          properties: {
            qListObjectDef: {
              qDef: {
                qSortCriterias: getSortExpression(fieldName, true),
              },
            },
          },
          search: true,
          toolbar: true,
          // searchOptions: {   //astericks searching function
          //   searchFunction: async (terms) => {
          //     const fieldValues = await app.getField(fieldName).getData();
          //     const matchingValues = fieldValues.filter((value) =>
          //       isMatch(value.qText, terms)
          //     );
          //     return matchingValues;
          //   },
          // },
        };
        app.abortModal({ qAccept: true });
        const n1 = embed(app);
        n1.field(fieldName).then((x) => {
          x.mount(document.querySelector('#main'+filterIndex), options);
          setMounterElm({ name: fieldName, index: filterIndex });
        });
      }
    }
  }, [expandedName]);

  //sets which accordion tab to expand
  if (expandedName === label && expanded === true) {
    expanded = true;
  } else expanded = false;


  // testing
  // const inputElement = document.querySelectorAll(`.selectables input`);
  // const inputElement = document.getElementsByClassName("selectables");
  // if (inputElement.length > 0) {
  //   inputElement.forEach(x => {
  //     x.value = '**';
  //   })
  // }


  const getLabel = (label) => {
    if (selVal) {
      if (Object.keys(selValLabels).includes(label)) {
        return (
          <>
            <span>{label}</span>{" "}
            <span style={{ color: "#92969C", alignSelf: "flex-end" }}>
              {selVal[[selValLabels[label]]]}
            </span>
          </>
        );
      }
    }

    return label;
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={collapseAccordion} id={label}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id={label}
          className="menuList"
          style={{
            borderRadius: "0",
            minHeight: "20px",
            maxHeight: "20px",
            paddingRight: "2px",
            display: "block !important",
          }}
          margin="0px !important"
          expandIcon={<ExpandMoreIcon />}
        >
          <div
            style={{
              float: "right",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                color: "#464E5C",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {getLabel(label)}
            </Typography>
          </div>
        </AccordionSummary>
        {true && (
          <DetailsWrapper>
            <AccordionDetails>
              <div
                // noWrap
                className="selectables"
                style={{ zIndex: 0, position: "relative", height: "200px"}}
              >
                <div style={{height: "200px"}} id={"main" + filterIndex}></div>
              </div>
            </AccordionDetails>
          </DetailsWrapper>
        )}
      </Accordion>
    </>
  );
};

export default Filter;
