import Filter from "./Filter";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@emotion/react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import LocalMallIcon from "@mui/icons-material/LocalMall";

const FilterGroup = ({
  groupName,
  filters,
  collapseAccordion,
  expanded,
  expandedName,
  prevExpandedName,
  startIndex,
  selVal,
}) => {
  const theme = useTheme();

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            backgroundColor: "#d7dedf",
            borderRadius: "0",
            minHeight: "25px",
            maxHeight: "25px",
          }}
        >
          <Typography sx={theme.menuHeading}>
            {groupName == "Calendar" ? (
              <CalendarTodayIcon fontSize="small" />
            ) : groupName == "Location" ? (
              <StorefrontRoundedIcon fontSize="small" />
            ) : groupName == "Product" ? (
              <LocalMallIcon fontSize="small" />
            ) : (
              ""
            )}
            {" " + groupName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px" }}>
          {filters &&
            filters.map((d, i) => (
              <Filter
                label={d}
                filterIndex={startIndex + i}
                collapseAccordion={collapseAccordion}
                expanded={expanded}
                expandedName={expandedName}
                prevExpandedName={prevExpandedName}
                selVal={selVal}
                key={`filter-group-${i}`}
              />
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FilterGroup;
