export const tdPerChg = ["LY", "LLY"]; //if selected, %Chg can be selected
export const LYPerChg = ["LY", "LLY", "LLLY", "%Chg"]; //dynamic TDs
export const tdLY = ["Day", "LW", "MTD", "QTD", "STD"]; //tds that have LY
export const noLY = ["YTD", "CP", "LTD"];

export const allTD = [
  "Day",
  "WTD",
  "LW",
  "MTD",
  "QTD",
  "STD",
  "YTD",
  "LTD",
  "LY",
  "LLY",
  "CP",
  "%Chg"
]; //all possible Ds

export const periods = ["LW", "MTD", "QTD", "STD", "YTD", "LTD", "CP"]; //static periods
export const valuestoAlign = [
  "Day",
  "WTD",
  "LW",
  "MTD",
  "STD",
  "QTD",
  "YTD",
  "CP",
  "LTD",
  "UPC",
  "Age",
  "MSRP",
  "%",
  "$",
  "ID",
  "Stock",
  //"Count",
  "Units",
  "Date",
  "Weeks",
  "5wk",
  "OH",
];

export const OHMeasures = [
  "OH Dollars",
  "OH Units",
  //"% Ttl OH U",
  //"% Ttl OH $",
  "On Order Units",
  "On Order Dollars"
];

export const dimSelTabs = ["style-detail", "door-detail", "data-availability"]; //tabs that allow clients to select dimensions with checkboxes
export const singleTimeDimTabs = ["dashboard", "geographic"]; //only one time dim
export const multTimeDimTabs = ["style-detail", "door-detail", "adhoc"]; //tabs that allow select multiple time dimensions
export const multGridTypeTabs = [
  "style-detail",
  "door-detail",
  "trending",
  "adhoc",
  "data-availability",
]; //tabs that allow select different grids
export const excelExportTabs = [
  "style-detail",
  "door-detail",
  "trending",
  "adhoc",
  "data-availability",
]; //tabs that allow excel export
export const gridDurationTabs = ["trending", "data-availability"]; //tabs with grid duration
export const unitSelTabs = ["dashboard"]; // tabs with unit selections

export const gridLengths = ["Top 10", "Top 25", "All"];
export const topGridLengths = ["Top 10", "Top 25"];

export const numericSort = ["Supplier Site ID", "Brand ID", "UPC", "Style ID"];
export const selValLabels = {
  "Week Ending Date": "wed",
  "Week #": "week",
  Year: "year",
  Month: "month",
};
export const dataAvailabiltyDurations = [
  {
    text: "aweek",
    key: "Week #",
  },
  {
    text: "awed",
    key: "Week Ending Date",
  },
];

export const gridDurations = [
  {
    text: "Day",
    value: "Day",
  },
  {
    text: "Week",
    value: "Week #",
  },
  {
    text: "WED",
    value: "Week Ending Date",
  },
  {
    text: "Month",
    value: "Month",
  },
  {
    text: "Quarter",
    value: "Quarter",
  },
  {
    text: "Season",
    value: "Season",
  },
  {
    text: "Year",
    value: "Year",
  },
];

export const determineGrid = {
  Day: "Day",
  WTD: "Week",
  LW: "Week",
  MTD: "Month",
  QTD: "Quarter",
  STD: "Season",
  YTD: "Year",
  LTD: "Year",
  CP: "Year",
};

export const periodInfo = {
  Day: { dataPts: 7, span: 7 },
  WTD: { dataPts: 5, span: 52 },
  LW: { dataPts: 5, span: 52 },
  MTD: { dataPts: 12, span: 12 },
  QTD: { dataPts: 4, span: 4 },
  STD: { dataPts: 2, span: 2 },
  YTD: { dataPts: 4, span: 1 },
  LTD: { dataPts: 1, span: 1 },
  CP: { dataPts: 1, span: 1 },
};

export const periodTypeSort = {
  Day: { field: "dayID", numSort: -1 },
  WTD: { field: "weekID", numSort: -1 },
  LW: { field: "weekID", numSort: -1 },
  MTD: { field: "monthID", numSort: -1 },
  STD: { field: "seasonID", numSort: -1 },
  QTD: { field: "quarterID", numSort: -1 },
  YTD: { field: "Year", numSort: -1 },
  CP: { field: "Year", numSort: 1 },
  LTD: { field: "Year", numSort: 1 },
};
export const firstColorSales = "#9F6CFD";
export const secondColorSales = "#d0b7fe";
export const firstColorInv = "#EC922B";
export const secondColorInv = "#f2b773";

export const defaultContext = {
  metric: "Dollars",
  period: "LW",
};

export const metrics = ['Units', 'Dollars'];
export const trendGridType = ['Line Chart', 'Pivot']
export const dataAvailabiltyType = ['Data Availability', 'Rejections Summary','UPC Rejections', 'SKYALIGN Rejections', 'Missing Dollars Exception']
export const defaultGridType = ['Straight', 'Pivot'];

export const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const isOH = (key) => {
  return OHMeasures.some((measure) => key.includes(measure));
};

export const isStaticTD = (val) => {
  return periods.includes(val);
};

export const isTopGL = (gl) => {
  return topGridLengths.includes(gl);
};
export const isPerChg = (td) => {
  if (td === "%Chg") {
    return true;
  }
  return false;
};

export const hasPerChg = (tds) => {
  if (tds.includes("%Chg")) {
    return true;
  }
  return false;
};
export const allowPerChg = (tds) => {
  return tdPerChg.some((td) => tds.includes(td));
};
export const isLTD = (td) => {
  return td === "LTD";
};

export const isLW = (td) => {
  return td === "LW";
};

export default {
  allowPerChg,
  tdPerChg,
  isPerChg,
  hasPerChg,
  allTD,
  periods,
  gridLengths,
  gridDurations,
  dataAvailabiltyDurations,
  metrics,
  trendGridType,
  dataAvailabiltyType,
  defaultGridType,
  multTimeDimTabs,
  LYPerChg,
  isOH,
  isStaticTD,
  valuestoAlign,
  determineGrid,
  defaultContext,
  periodInfo,
  tdLY,
  noLY,
  firstColorSales,
  secondColorSales,
  firstColorInv,
  secondColorInv,
  periodTypeSort,
  topGridLengths,
  isTopGL,
  multGridTypeTabs,
  dimSelTabs,
  singleTimeDimTabs,
  gridDurationTabs,
  unitSelTabs,
  numericSort,
  selValLabels,
  isLTD,
  isLW,
  timeout
};
