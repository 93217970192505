import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Checkbox,
  Typography,
  Tooltip,
  InputAdornment,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/styles";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import $ from "jquery";
import { useSelectionObject } from "../../services/qlikDataServices/useSelectionObject";
import { v4 } from "uuid";
import { useSnackbar } from "notistack";
import { ApiContext } from "../../contexts/context";
import LoadingButton from '@mui/lab/LoadingButton';
import SharedBookmarkList from "./SharedBookmarkList";
import { timeout } from "../../utils/helpers";

const jsonPrase = (myVar) => {
  if (typeof myVar === 'string' || myVar instanceof String) {
    return JSON.parse(myVar);
  }
  return myVar
}

const ListItemWrapper = styled(Box)(
  () => `
  `,
);

const DialogWrapper = styled(Dialog)(
  () =>
    `   .MuiPaper-root {
          border-radius: 5px;
          width: 450px;
          padding:10px 0;
    }
  `,
);

const MenuUserBox = styled(Box)(
  ({ theme }) =>
    `background: ${theme.colors.alpha.black[5]};
     padding: ${theme.spacing(1)};`,
);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 305,
    border: '1px solid #eee'
  },
  buttonIcon: {
    transform: "rotate(0deg)",
    marginLeft: "auto !important",
  },
  avatar: {
    fontSize: "2.5rem !important",
  },
  bookmarkContainer: {
    minHeight: "10vh",
    maxHeight: "40vh",
    overflow: "auto",
  },
  listItem: {
    padding: theme.spacing(0.5, 2),
    "&:hover": {
      color: theme.colors.primary.main,
      padding: theme.spacing(0.5, 2),
    },
    "& .MuiListItemText-root": {
      cursor: "pointer",
      padding: theme.spacing(1, 2),
      "& .MuiTypography-root": {
        fontSize: "12px",
        "&:before": {
          height: "4px",
          width: "22px",
          opacity: 0,
          visibility: "hidden",
          display: "block",
          position: "absolute",
          bottom: "-10px",
          transition: "all .2s",
          borderRadius: theme.general.borderRadiusLg,
          content: "",
          background: theme.colors.primary.main,
        },
      },
    },
  },
  userBoxLabel: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
    display: "block",
  },
  userBoxText: {
    textAlign: "left",
    paddingLeft: theme.spacing(1),
    marginTop: "auto",
    fontSize: "12px",
    marginBottom: "auto",
  },
}));

function HeaderUserbox({ closeBookmark }) {
  const classes = useStyles();
  const navigate = useHistory();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const { bookmarkSetSelections } = useSelectionObject();

  const [open, setOpen] = useState(false);
  const [bookmarkID, setBookmarkID] = useState(null);
  const [bookmarks, setBookmarks] = useState(null);
  const [tempbookmarks, setTempBookmarks] = useState(null);
  const [selectedBookmarks, setSelectedBookmarks] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkShare, setCheckShare] = useState(false);
  const [isAlreadyUse, setIsAlreadyUse] = useState(false);
  const [isUpdate, setIsUpdateClick] = useState(false);
  const { BookmarkAPI, getUsers } = useContext(ApiContext);
  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const [showTeamList, setShowTeamList] = React.useState(false);
  const [isCheck, setIsCheck] = React.useState([]);
  const [teamList, setTeamList] = React.useState([]);

  useEffect(() => {
    const appId = JSON.parse(sessionStorage.getItem('selApp')).qDocId;
    if (bookmarks) {
      getUsers(appId).then(
        (response) => {
          if (response && response.length > 0) {
            setTeamList(response);
          }
        },
        (reject) => {
          console.log(reject);
        },
      );
    }
  }, [bookmarks]);

  const clickedShare = (event) => {
    setCheckShare(event.target.checked);
    if (event.target.checked) {
      setShowTeamList(true)

    } else setShowTeamList(false)
  };

  const dateString = () => {
    const now = new Date();
    // Get ISO 8601 date and time string
    const isoString = now.toISOString();
    const date = isoString.slice(0, 19);
    return date;
  }
  const handleClearX = (e) => {
    setTempBookmarks(bookmarks)
    $("#bookmarkSearch").val("");
    $("#bookmarkBtn").hide();

  };

  const searchField = (value) => {

    if (value !== '') {
      let tempCN1 = bookmarks.filter(
        (x) =>
          x.name.toUpperCase().includes(value.toUpperCase().trim())
      );
      setTempBookmarks(tempCN1)
      $("#bookmarkBtn").show();
    } else {
      setTempBookmarks(bookmarks)
      $("#bookmarkBtn").hide();
    }
  };

  useEffect(async () => {
    BookmarkAPI.get(
      jsonPrase(sessionStorage.getItem("selApp")).qDocId,
      user.email,
      user.nickname,
    ).then(
      (response) => {
        if (Array.isArray(response)) {
          response.sort((a, b) => a.name.toUpperCase() - b.name.toUpperCase());
          setBookmarks(response);
          setTempBookmarks(response)
        }
      },
      (reject) => {
        console.log(reject);
      },
    );
  }, [anchorEl]);



  const saveBookmark = async () => {

    let bookmarkName = $("#book_outlined-basic").val();
    var retrievedData1 = sessionStorage.getItem("selections");
    var navStates = JSON.parse(sessionStorage.getItem("navStates"));
    //let isUpdate = $("#bookmark-button").text() === "Update Bookmark";
    await timeout(500);

    if (bookmarkName && bookmarkName !== "") {
      setLoadingBtn(true)
      if (!isUpdate && bookmarks.length > 0 && bookmarks.filter((x) => x.name.trim() === bookmarkName.trim()).length > 0) {
        setIsAlreadyUse(true);
        setLoadingBtn(false)
        return;
      } else {
        setIsAlreadyUse(false);
        //setLoadingBtn(false)
      }


      let bookmarkObj = {
        bookmark_id: isUpdate ? selectedBookmarks.bookmark_id : null,
        name: bookmarkName,
        pageName: window.location.href.split("/").pop().toLowerCase(),
        isField: true, //$('#fieldscheckbox').is(':checked'),
        isSelection: true, // $('#selections').is(':checked'),
        isShared: $("#sharedCheckbox").is(":checked"),
        shared_users: isCheck.toString(),
        selections: retrievedData1,
        author: user.email,
        app_id: jsonPrase(sessionStorage.getItem("selApp")).qDocId,
        modified_date: dateString()

      };

      if (bookmarkObj.isField) {
        let bd1 = jsonPrase(sessionStorage.getItem("navStates"));

        if (bookmarkObj.pageName === "dashboard") {
          bookmarkObj["bookmark_body"] = {
            period: navStates.timeDimension,
            //metric: $("#dashboard-unit-selection")[0] ? $("#dashboard-unit-selection")[0].innerText : '',
          };
        } else if (bookmarkObj.pageName === "style-detail") {
          bookmarkObj["bookmark_body"] = {
            period: bd1.multiSelectTimeDimension.toString(),
            gridLength: bd1.gridLength,
            gridTypeSD: bd1.gridTypeSD,
            gridStats: jsonPrase(bd1["StyleDetailsGridStats"]),
          };
        } else if (bookmarkObj.pageName === "door-detail") {
          bookmarkObj["bookmark_body"] = {
            period: bd1.multiSelectTimeDimension.toString(),
            gridLength: bd1.gridLength,
            gridTypeDD: bd1.gridTypeDD,
            gridStats: jsonPrase(bd1["DoorDetailsGridStats"]),
          };
        } else if (bookmarkObj.pageName === "geographic") {
          bookmarkObj["bookmark_body"] = {
            period: $("#dashboard-period-selection")[0].innerText,
          };
        } else if (bookmarkObj.pageName === "trending") {
          let tempState =
            bd1.trendingChartType === "Pivot"
              ? jsonPrase(bd1["TrendingGridStats"])
              : jsonPrase(bd1["tlChartVal"]);
          bookmarkObj["bookmark_body"] = {
            period: bd1.timeTypeSelection,
            trendingChartType: bd1.trendingChartType,

            gridStats: tempState,
          };
        } else if (bookmarkObj.pageName === "adhoc") {
          bookmarkObj["bookmark_body"] = {
            period: bd1.multiSelectTimeDimension.toString(),
            gridTypeAH: bd1.gridTypeAH,
            gridStats: jsonPrase(bd1["AdHocGridStats"]),
          };
        }
      } else {
        bookmarkObj["bookmark_body"] = {};
      }

      BookmarkAPI.save(bookmarkObj, teamList).then(
        () => {
          setLoadingBtn(false)
          let msg =
            isUpdate
              ? "updated"
              : "created";
          cancelUpdate();
          setAnchorEl(v4());
          enqueueSnackbar(`Bookmark ${msg}`, {
            variant: "success",
            className: "snackBarClass",
            autoHideDuration: 5000,
          });

        },
        (e) => {
          cancelUpdate();
        },
      )
    }
  };

  //this code will be used in future for qlik bookmark 

  // BookmarkAPI.save(bookmarkObj, teamList).then(
  //   (response) => {
  //     (async () => {
  //       if ($('#bookmark-button').text() === 'Update Bookmark') {
  //         await qDoc.destroyBookmark(response);
  //       }
  //       qDoc.createBookmark({
  //         "qInfo": {
  //           "qId": response,
  //           "qType": "Bookmark"
  //         }
  //       }).then(async val => {
  //         if (val) {
  //           let msg =
  //             $("#bookmark-text").text() === "Update Bookmark"
  //               ? "updated"
  //               : "created";
  //           cancelUpdate();
  //           setAnchorEl(v4());
  //           enqueueSnackbar(`Bookmark ${msg}`, {
  //             variant: "success",
  //             className: "snackBarClass",
  //             autoHideDuration: 5000,
  //           });
  //           setLoadingBtn(false)
  //         } else {
  //           bookmarkObj['bookmark_id'] = response.data;
  //           deleteBookmark(bookmarkObj)
  //         }
  //       })
  //     })()


  //   },
  //   (e) => {
  //     cancelUpdate();
  //   },
  // )

  const setBookmarksFieldState = (bookmarkObj) => {
    var retrievedData = sessionStorage.getItem("navStates");
    let bd1 = jsonPrase(retrievedData);
    bd1["redirectFromBookmark"] = true;
    bd1["bookmarkName"] = bookmarkObj.name;
    bd1["bookmarkPage"] = bookmarkObj.page;
    let bookmark_body = jsonPrase(bookmarkObj.bookmark_body);
    if (bookmarkObj.page === "dashboard") {
      bd1["unit"] = bookmark_body.metric;
      bd1["timeDimension"] = bookmark_body.period;
    } else if (bookmarkObj.page === "style-detail") {
      sessionStorage.removeItem("sStraightTableColumns");
      bd1["multiSelectTimeDimension"] = bookmark_body.period.split(",");
      bd1["gridLength"] = bookmark_body.gridLength;
      bd1["gridTypeSD"] = bookmark_body.gridTypeSD;
      bd1["StyleDetailsGridStats"] = bookmark_body.gridStats;
    } else if (bookmarkObj.page === "door-detail") {
      sessionStorage.removeItem("dStraightTableColumns");
      bd1["multiSelectTimeDimension"] = bookmark_body.period.split(",");
      bd1["gridLength"] = bookmark_body.gridLength;
      bd1["gridTypeDD"] = bookmark_body.gridTypeDD;
      bd1["DoorDetailsGridStats"] = bookmark_body.gridStats;
    } else if (bookmarkObj.page === "geographic") {
      bd1["geotype"] = bookmark_body.geotype;
      bd1["timeDimension"] = bookmark_body.period;
    } else if (bookmarkObj.page === "trending") {
      bd1["timeTypeSelection"] = bookmark_body.period;
      bd1["trendingChartType"] = bookmark_body.trendingChartType;
      if (bookmark_body.trendingChartType === "Pivot") {
        bd1["TrendingGridStats"] = bookmark_body.gridStats;
      } else {
        bd1["tlChartVal"] = bookmark_body.gridStats;
      }
    } else if (bookmarkObj.page === "adhoc") {
      bd1["multiSelectTimeDimension"] = bookmark_body.period.split(",");
      bd1["AdHocGridStats"] = bookmark_body.gridStats;
      bd1["gridTypeAH"] = bookmark_body.gridTypeAH;
    }

    bd1["isPerChg"] = bd1.multiSelectTimeDimension.includes("%Chg");
    sessionStorage.setItem("navStates", JSON.stringify(bd1));
  };

  const selectBookmark = (bookmark) => {
    bookmarkSetSelections(jsonPrase(bookmark.selections));
    setBookmarksFieldState(bookmark);
    closeBookmark(null);
    navigate.push(`/bm/${bookmark.page ? bookmark.page : bookmark.pageName}`);
  };

  const openDeletePopup = async (bm) => {
    setOpen(true);
    setBookmarkID(bm);
  };
  const deleteBookmark = async (bm) => {
    setOpen(false);
    BookmarkAPI.delete(bm.bookmark_id).then(
      () => {
        if (bm.is_selection) {
          cancelUpdate();
          enqueueSnackbar("Bookmark deleted!", {
            variant: "success",
            className: "snackBarClass",
            autoHideDuration: 5000,
          });
          setAnchorEl(v4());
        }
      },
      () => {
        cancelUpdate();
      },
    );
  };

  const handleClose = () => {
    setOpen(false);
   
  };

  const updateBookmark = async (bm) => {
    $("#book_outlined-basic").val(bm.name);
    //$("#bookmark-button").text("Update Bookmark");
    //$("#bookmark-text").text("Update Bookmark");
    setIsUpdateClick(true);
    $("#info-btn").show();
    let teamUsers = teamList.map(item => item.id);
    let sharedUsers = bm.shared_users === "all" ? teamUsers : bm.shared_users.split(",");
    setIsCheck(sharedUsers);
    setShowTeamList(bm.is_shared)
    setCheckShare(bm.is_shared);
    setSelectedBookmarks(bm);
    setIsAlreadyUse(false);
  };

  const cancelUpdate = () => {

    $("#book_outlined-basic").val("");
    // $("#bookmark-button").text("Create Bookmark");
    // $("#bookmark-text").text("Create Bookmark");
    $("#info-btn").hide();
    setIsCheck([]);
    setCheckShare(false);
    setSelectedBookmarks(null);
    setIsAlreadyUse(false);
    setIsUpdateClick(false);
  };

  return (
    <>
      <Box className={classes.root}>
        <MenuUserBox display="flex">
          <BookmarkBorderIcon
            className={classes.avatar}
            variant="rounded"
            alt={window.userId}
            src=""
          />
          <Box className={classes.userBoxText}>
            <Typography className={classes.userBoxLabel} variant="h4">
              {t("Viewing All Bookmarks")}
            </Typography>
            You have <b></b>
            {bookmarks ? bookmarks.length : 0} bookmarks
          </Box>
        </MenuUserBox>

        <Divider sx={{ mb: 0, borderTop: "1px solid" }} />

        {bookmarks && bookmarks.length > 0 ?
          <TextField
            variant="outlined"
            className={classes.adHocSearchBox}
            sx={{ width: "100%", p: 1, background: "white", '& input': { padding: '6px' } }}
            placeholder="Search"
            size="small"
            id="bookmarkSearch"
            onKeyUp={(e) => searchField(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="bookmarkBtn"
                    style={{ display: "none" }}
                    onClick={handleClearX}
                    size="small"
                    edge="end"
                  >
                    <HighlightOffIcon color="error" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          : ''}

        <Divider sx={{ mb: 0, borderTop: "1px solid" }} />

        <List component={Box}>
          <div className={classes.bookmarkContainer}>
            {!tempbookmarks ? (
              <>Loading bookmark... Please wait</>
            ) : tempbookmarks.length > 0 ? (
              <>
                {tempbookmarks.map((bm) => {
                  return (
                    <>
                      <ListItem
                        key={`bookmark-list-${bm.name}`}
                        className={classes.listItem}
                        classes={{ root: "MuiListItem-indicators" }}
                      >
                        <ListItemWrapper>
                          <ListItemText
                            onClick={() => selectBookmark(bm)}
                            primary={bm.name}
                          />
                        </ListItemWrapper>
                        {bm.author === user.email &&
                          (!selectedBookmarks ? (
                            <ListItemWrapper className={classes.buttonIcon}>
                              <Tooltip
                                title="Update Bookmark"
                                placement="top-start"
                              >
                                <IconButton
                                  sx={{ padding: 1 }}
                                  onClick={() => updateBookmark(bm)}
                                >
                                  <EditIcon></EditIcon>
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title="Delete Bookmark"
                                placement="top-start"
                              >
                                <IconButton
                                  sx={{ padding: 1 }}
                                  onClick={() => openDeletePopup(bm)}
                                >
                                  <DeleteIcon></DeleteIcon>
                                </IconButton>
                              </Tooltip>
                            </ListItemWrapper>
                          ) : bm.bookmark_id === selectedBookmarks.bookmark_id ? (
                            <ListItemWrapper className={classes.buttonIcon}>
                              <Tooltip title="Cancel" placement="top-start">
                                <IconButton
                                  sx={{ padding: 1 }}
                                  onClick={() => cancelUpdate()}
                                >
                                  <CancelIcon></CancelIcon>
                                </IconButton>
                              </Tooltip>
                            </ListItemWrapper>
                          ) : (
                            ""
                          ))}
                      </ListItem>
                      <Divider variant="middle" sx={{ borderTop: "1px solid" }} />
                    </>
                  );
                })}
              </>
            ) : (
              <Typography sx={{ padding: "8px 16px" }}>
                No bookmarks available
              </Typography>
            )}
            <Divider variant="inset" component="li" />
          </div>

          <Divider />
          <MenuUserBox>
            <ListItem sx={{ padding: 1 }}>
              <ListItemWrapper>
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: "0px 0px 0px 15px", marginBottom: 0.5 }}
                >
                  <Grid>
                    <Typography
                      className={classes.userBoxLabel}
                      id="bookmark-text"
                      variant="h4"
                      sx={{ lineHeight: "inherit", margin: "0px 5px 0 0" }}
                    >
                       {isUpdate ? "Update " : "Create "} Bookmark
                    </Typography>
                  </Grid>


                  <Grid
                    sx={{
                      lineHeight: "inherit",
                      cursor: "pointer",
                      display: "none",
                    }}
                    id="info-btn"
                  >
                    <Tooltip
                      title='Clicking "Update Bookmark" will replace this bookmark with the current report you are viewing in SKYPAD'
                      placement="top-start"
                    >
                      <InfoIcon></InfoIcon>
                    </Tooltip>
                  </Grid>
                </Grid>

                <TextField
                  id="book_outlined-basic"
                  required
                  size="small"
                  variant="outlined"
                  sx={{ '& input': { padding: '6px' } }}
                />
              </ListItemWrapper>
              <ListItemWrapper
                className={classes.buttonIcon}
                sx={{ padding: "0 15px", margin: "18px 0px 0px 15px" }}
              >
                <Grid container spacing={2}>
                  <Grid>
                    <Checkbox
                      sx={{ verticalAlign: "middle" }}
                      id="sharedCheckbox"
                      onChange={clickedShare}
                      checked={checkShare}
                      size="small"
                    />
                  </Grid>
                  <Grid sx={{ margin: "auto", width: "5rem", lineHeight: 1, fontSize: '0.8rem' }}>
                    Share with your team
                  </Grid>
                </Grid>
              </ListItemWrapper>
            </ListItem>
            {showTeamList &&
              <Grid
                sx={{
                  lineHeight: "inherit",
                  cursor: "pointer",
                  padding: 1
                }}
                id="info-btn"
              >
                <SharedBookmarkList classes={classes} isCheck={isCheck} setIsCheck={setIsCheck} list={teamList} />
              </Grid>
            }
            {isAlreadyUse && (
              <>
                <p style={{ color: "red", paddingLeft: "8px" }}>
                  A bookmark with this name already exists
                </p>
              </>
            )}
            <ListItem sx={{ padding: 1 }} alignItems="center">

              <LoadingButton onClick={() => saveBookmark()}
                loading={loadingBtn}
                id="bookmark-button"
                loadingIndicator={isUpdate ? "Updating..." : "Creating..."}
                size="small" variant="contained">
                {isUpdate ? "Update " : "Create "} Bookmark
              </LoadingButton>
            </ListItem>
          </MenuUserBox>
        </List>
      </Box>

      {/* shows when delete button is clicked */}
      <DialogWrapper open={open} onClose={handleClose}>
        <DialogContent sx={{ pt: "0 !important" }}>
          <Typography variant="h6" sx={{ pt: 2, pb: 2 }}>
            Are you sure you want to delete this bookmark?
          </Typography>
          <Typography variant="h6" sx={{ pb: 1 }}>
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            onClick={() => deleteBookmark(bookmarkID)}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="info"
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogWrapper>
      {/* shows when delete button is clicked */}
    </>
  );
}

export default HeaderUserbox;
