import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Stack,
    Card,
    List,
    ListItem,
    ListItemText,
    TextField,
    Checkbox,
    Typography,
    InputAdornment,
    FormControlLabel,
    FormGroup,
    Divider,
} from "@mui/material";
import ListItemButton from '@mui/material/ListItemButton';
import SearchIcon from "@mui/icons-material/Search";
export default function CheckboxList({ classes, setIsCheck, isCheck, list }) {

    function generateRandomArrayWithEmailandID() {
        let arr = [];
        for (let i = 0; i < 50; i++) {
            let obj = {
                name: "test" + i + "@gmail.com",
                id: i,
            };
            arr.push(obj);
        }
        return arr;
    }

    // let list = generateRandomArrayWithEmailandID()

    const [userList, setUserList] = useState([]);
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    // const [isCheck, setIsCheck] = React.useState([]);

    useEffect(() => {
        setUserList(list)
    }, [list])

    const handleCheck = (id) => () => {
        const currentIndex = isCheck.indexOf(id);
        const newChecked = [...isCheck];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setIsCheck(newChecked)
    };

    const handleCheckAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(userList.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const searchSharedUsers = (value) => {

        if (value !== '') {
            let tempCN1 = list.filter(
                (x) =>
                    x.name.toUpperCase().includes(value.toUpperCase().trim())
            );
            setUserList(tempCN1)
        } else {
            setUserList(list)
        }
    };
    return (
        <Card elevation={3} sx={{ border: '1px solid #ccc' }} >

            <Box className={classes.userBoxText}
                sx={{ textAlign: 'center !important', backgroundColor: '#dde2e3', padding: '3px 12px' }} >
                <Typography className={classes.userBoxLabel} variant="h4">
                    Select from the list of users
                </Typography>
            </Box>
            <Stack direction="row" flexWrap="wrap">
                <FormGroup sx={{ padding: '4px' }}>
                    <FormControlLabel sx={{ margin: 'auto' }} control={<Checkbox
                        disableRipple
                        id="selectAll"
                        name="selectAll"
                        onClick={handleCheckAll}
                        isChecked={isCheckAll}
                        size="small"
                        sx={{ padding: '4px 6px' }}
                    />}
                        label="Select All" />

                    {/* <ListItemText primary="Select All" /> */}

                </FormGroup>
                <Box>
                    <TextField
                        variant="outlined"
                        className={classes.adHocSearchBox}
                        sx={{ width: "100%", p: 1, background: "white", '& input': { padding: '6px' }, width: '195px' }}
                        placeholder="Search"
                        size="small"
                        id="bookmarkSearch"
                        onKeyUp={(e) => searchSharedUsers(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
            </Stack>

            <Divider sx={{ mb: 0, borderTop: "1px solid" }} />
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    padding: '0 5px',
                    overflowX: 'auto',
                    height: 'calc(22vh - 2px)'
                }}>
                {userList.map(({ id, name }) => {
                    const labelId = `checkbox-list-label-${id}`;
                    return (
                        <ListItem key={id} disablePadding>
                            <ListItemButton role={undefined} onClick={handleCheck(id)} sx={{ padding: 0 }} dense>
                                <Checkbox
                                    sx={{ padding: '4px 6px' }}
                                    key={id}
                                    type="checkbox"
                                    name={name}
                                    id={id}
                                    checked={isCheck.includes(id)}
                                    size="small"
                                />
                                <ListItemText id={labelId} primary={name} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
            <Divider sx={{ mb: 0, borderTop: "1px solid" }} />
        </Card>
    );
}