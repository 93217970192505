import { Router, useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import { renderRoutes, getRoutes } from "./router";
import ScrollToTop from "../../utils/ScrollToTop";
import { CssBaseline } from "@mui/material";
import { capitalCase } from "capital-case";
import { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiContext } from "../../contexts/ApiContext";
import { isReportsUser } from "src/utils/isReportsUser";
import { Userpilot } from 'userpilot'
// Initialize Userpilot
Userpilot.initialize('NX-5a2ab392');

const history = createBrowserHistory();

history.listen((location) => {
  Userpilot.reload();
  console.log(location);
});

const Route = ({ routes }) => {
  let tab = capitalCase(history.location.pathname);
  let historyHook = useHistory();
  const { user } = useAuth0();
  const { updateHistory } = useContext(ApiContext);

  useEffect(() => {
    if (user) {
      Userpilot.identify(
        user.name,
        {
          name: user.name,
          email: user.email,
          created_at: user.created_at,
          security_groups: user["http://skypad_user/groups"].toString()
        }
      );
      Userpilot.reload();
    }

  }, [user])


  useEffect(() => {
    if (!historyHook && user) {
      updateHistory(history);
      let t = user["http://skypad_user/groups"].filter(x => x === "QlikSense Users").length;
      if (t < 1) {
        window.location.href = "https://red.skypad.biz/";
      } else {
        const selApp = JSON.parse(sessionStorage.getItem("selApp"));
        if (selApp) {
          if (history.location.pathname === "/") {
            let t = user["http://skypad_user/groups"].filter(x => x === "ICP ReportManager").length;

            if (isReportsUser(user)) {
              history.push("/reports");
            } else {
              history.push("/dashboard");
            }
          }
        } else {
          history.push("/accesspoint");
        }
      }
    }
  }, [historyHook]);

  return (
    // routes.length > 0 ?
    <Router history={history}>
      <ScrollToTop />
      <CssBaseline />
      {renderRoutes(getRoutes(routes))}
    </Router>
    //  :
    // <SpinnerDashboard tab={tab} />
  );
};

export default Route;
