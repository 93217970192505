import PropTypes from "prop-types";
import Sidebar from "../../layout/Sidebar/SidebarGroup";
import Footer from "../Footer";
import Header from "./Header";
import { makeStyles } from "@mui/styles";
import { Container, selectClasses } from "@mui/material";
import ContentWrapper from "../../components/ContentWrapper";
import React, { useEffect, useContext } from "react";
import {
  QlikContext,
  ConfigContext,
  OverlayContext,
  CurrentTimeFrameContext,
  CurrencyContext
} from "../../contexts/context";
import { useSelectionObject } from "../../services/qlikDataServices/useSelectionObject";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "unset",
  },
  drawerPaper: {
    padding: "0px",
    backgroundColor: theme.palette.grey,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // appBarSpacer: { marginTop: '55px' },
  content: {
    flexGrow: 1,
    height: "100vh",
    paddingBottom: "30px",
    paddingTop: "54px",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(0.4),
    paddingBottom: theme.spacing(0.3),
  },
}));



const SidebarLayout = ({ children }) => {
  const classes = useStyles();
  const { configVal, getConfigValForApp } = useContext(ConfigContext);
  const { qDoc, setQlikConnection } = useContext(QlikContext);
  const { OverlayAction } = useContext(OverlayContext);
  const { toggleSelections } = useSelectionObject();
  let { setCurrentTimeFrameContext } = useContext(CurrentTimeFrameContext);
  const { setCurrency } = useContext(CurrencyContext);

  useEffect(() => {
    OverlayAction.start("Getting tab data");
    const selApp = JSON.parse(sessionStorage.getItem("selApp"));
    setQlikConnection(selApp.qDocId);
  }, []);

  useEffect(() => {
    if (qDoc) {
      const selApp = JSON.parse(sessionStorage.getItem("selApp"));
      getConfigValForApp(selApp.title);
    }
  }, [qDoc]);

  const getLastSaturday = (date) => {
    var dateToUse = new Date(date);
    var start = dateToUse.getDay() == 0 ? 7 : dateToUse.getDay();
    var target = 6; // Saturday

    if (target >= start)
      target -= 7;

    let diff = target - start;

    diff = dateToUse.getDay() === 0 ? -8 : dateToUse.getDay() === 6 ? -7 : diff;
    var lastSaturday = addDays(dateToUse, diff);

    return lastSaturday.toLocaleDateString('en-US');
  }

  const addDays = function (dateToUse, days) {
    var date = new Date(dateToUse.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  useEffect(async () => {
    if (configVal) {
      let defaultFilter = configVal.find(x => x.type === 'Initialization' && x.subtype === 'Default Selections');

      if (defaultFilter) {
        defaultFilter = defaultFilter.value[0];


        let currentWED = getLastSaturday(new Date());

        const qProp = {
          qInfo: { qType: "SelectionObject" },
          qSelectionObjectDef: {},
        };

        let qObject = await qDoc.createSessionObject(qProp);
        let test4 = await qObject.getLayout();

        let test5 = test4.qSelectionObject.qSelections.find(x => x.qField === "Week Ending Date");


        if (!test5 || (currentWED !== test5.qSelected)) {
          toggleSelections(defaultFilter, currentWED);
        } 
      }
      setCurrentTimeFrameContext('LW', qDoc);

      let selectCurrency = configVal.find(x => x.type === "Currency" && x.subtype === 'Select Currency')
      let showCurrency = configVal.find(x => x.type === 'Currency' && x.subtype === 'Activate');
      let defaultCurrency;

      if (showCurrency && selectCurrency) {
        selectCurrency = selectCurrency.value
        defaultCurrency = selectCurrency.find(x => x.order === 1).key;
        setCurrency(selectCurrency.find(c => c.key === defaultCurrency), qDoc)
      }
      OverlayAction.stop();
    }
  }, [configVal]);

  return configVal && qDoc ? (
    <div className={classes.root}>
      <Header />
      <div className={classes.drawerPaper}>
        <Sidebar />
      </div>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="xxl" className={classes.container} sx={{marginBottom:1}}>
          <ContentWrapper
            title={JSON.parse(sessionStorage.getItem("selApp")).title}
          >
            {children}
          </ContentWrapper>
        </Container>
      </main>
      <Footer />
    </div>
  ) : (
    <></>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default SidebarLayout;
