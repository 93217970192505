import { useContext, useEffect, useRef, useState } from "react";
import { QlikContext } from "../../contexts/QlikContext";
import $ from "jquery";

let qDoc = null;
let qObject = null;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const useSelectionObject = () => {
  let selections = null;
  const { qDoc } = useContext(QlikContext);
  const _isMounted = useRef(true);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const update = async () => {
    try {
      const _qLayout = await qObject.getLayout();

      if (_isMounted.current) {
        selections = _qLayout && _qLayout.qSelectionObject.qSelections;
        let tempSelections =
          selections &&
          selections.map((x) => {
            return [
              x.qField,
              x.qSelectedFieldSelectionInfo.map((y) => y.qName).toString(),
            ];
          });

        let prevSel = JSON.parse(sessionStorage.getItem("selections"));

        sessionStorage.setItem("selections", JSON.stringify(tempSelections));

        if (tempSelections.length < prevSel.length) {
          let intersection = [];
          prevSel.map((x) => {
            if (!tempSelections.some((y) => y[0] === x[0])) {
              intersection = x;
            }
          });

          let test = $(".curr-selections .css-q8y8g7");
          test.toArray().forEach((x) => {
            if (x.children[0].children[0].innerHTML === intersection[0]) {
              x.parentNode.style.display = "none";
            }
          });
        } else if (tempSelections.length > prevSel.length) {
          let test = $(".curr-selections .css-q8y8g7");
          test.toArray().forEach((x) => {
            if (x.parentNode.style.display === "none") {
              x.parentNode.style.display = "block";
            }
          });
        }
      }

      let id = sessionStorage.getItem("pivotID");
      if (id) {
        const object = await qDoc.getObject(id);
        const qw = await object.getProperties();//delays the time to find the error and change it
        const layout = await object.getLayout();
        if (layout.qHyperCube.qError) {
          //await sleep(100);
          let pivotErr = document.getElementById("pivotErr");
          let pivotDiv = document.getElementsByClassName("pivotDiv");
          let elements = document.querySelectorAll('[data-tid="error-title"]');
          elements[0] && (elements[0].innerHTML = "Data is too large to process. Please filter down your data then try creating the report again.");
        }else{
          let pivotDiv = document.getElementsByClassName("pivotDiv");
          let pivotErr = document.getElementById("pivotErr");
        }
      }

    } catch (ex) {
      console.log(ex);
    }
  };

  const clearSelections = async (field, value) => {
    if (field) {
      const qField = await qDoc.getField(field);
      if (value) {
        let valArr = value.split(",");
        await valArr.forEach((element) => {
          qField.toggleSelect(element);
        });
      } else {
        await qField.clear();
      }
    } else {
      qDoc.abortModal({ qAccept: true });
      qDoc.clearAll();
    }
  };

  function selectBookmark(arr) {
    try {
      arr.forEach((xv) => {
        toggleSelections(xv[0], xv[1]);
      })
    } catch (ex) {
      console.log(ex);
    }
  }

  const bookmarkSetSelections = async (bookSelArr) => {
    qDoc.abortModal({ qAccept: true });
    await qDoc.clearAll();
    if (bookSelArr) {
      selectBookmark(bookSelArr);
    }
  };

  const toggleSelections1 = async (field, value) => {
    if (field) {
      const qField = await qDoc.getField(field);
      if (value) {
        let valArr = value.split(",").map((x) => {
          return { qText: x };
        });
        qField.selectValues(valArr, false, false);
      }
    }
  };

  const toggleSelections = async (field, value) => {
    if (field) {
      const qField = await qDoc.getField(field);
      if (value) {
        let valArr = value.split(",");
        await valArr.forEach((element) => {
          qField.toggleSelect(element);
        });
      } else {
        await qField.clear();
      }
    } else {
      qDoc.clearAll();
    }
  };

  useEffect(() => {

    (async () => {
      try {
        const qProp = {
          qInfo: { qType: "SelectionObject" },
          qSelectionObjectDef: {},
        };
        qObject = await qDoc.createSessionObject(qProp);
        qObject.on("changed", () => {
          update();
        });
        update();
      } catch (ex) {
        console.log(ex);
      }
    })();
  }, []);

  useEffect(() => () => (_isMounted.current = false), []);

  return {
    clearSelections,
    toggleSelections,
    toggleSelections1,
    bookmarkSetSelections,
  };
};

export async function setVariable(qDoc, durationVariable) {
  let x;
  try {
    x = await qDoc.getVariableByName("show_TrendsBy");
    await x.setProperties(durationVariable).then(() => true);
  } catch (e) {
    await qDoc.createSessionVariable(durationVariable);
  }
}
